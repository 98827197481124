import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

export type RoundedElevatedCardBgColors = 'white' | 'primary'

interface Props extends PropsWithChildren {
  backgroundColor?: RoundedElevatedCardBgColors
  className?: string
}

export const RoundedElevatedCard = ({
  backgroundColor = 'white',
  className,
  children,
}: Props) => (
  <div
    className={mergeClasses(
      'flex w-full flex-col rounded-2xl border p-6 shadow-md',
      {
        'border-info-100 bg-white': backgroundColor === 'white',
        'border-primary-100 bg-primary-90': backgroundColor === 'primary',
      },
      className
    )}
  >
    {children}
  </div>
)
