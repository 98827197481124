import {
  useBannerActions,
  useHasBannerBeenClosed,
} from '../store/useBannerStore'
import { EnumBannerKey } from '../types/banner'

interface Props {
  bannerKey: EnumBannerKey
  displayCondition: boolean
}

export const useBanner = ({ bannerKey, displayCondition }: Props) => {
  const { addBannerToHide } = useBannerActions()
  const hasBannerBeenClosed = useHasBannerBeenClosed(bannerKey)

  const isBannerToBeDisplayed = displayCondition && !hasBannerBeenClosed
  const hideBanner = () => addBannerToHide(bannerKey)

  return {
    isBannerToBeDisplayed,
    hideBanner,
  }
}
