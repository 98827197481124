import { EnumLocalStorageKeys } from '_constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { EnumPersistentModalKey } from '../types/persistentModal'

const MODAL_LAST_SEEN_DATES_DEFAULT_VALUES = {
  [EnumPersistentModalKey.ACCOUNT_PAGE_UPSELL]: undefined,
  [EnumPersistentModalKey.ACTIVATE_TOKAMAK_CARD]: undefined,
}

interface PersistentModalsStore {
  dismissedModals: EnumPersistentModalKey[]
  modalLastSeenDates: Record<EnumPersistentModalKey, Date | undefined>
}
interface PersistentModalsActions {
  addToDismissedModals: (modalKey: EnumPersistentModalKey) => void
  clearDismissedModals: () => void
  setModalLastSeenDate: (modalKey: EnumPersistentModalKey) => void
}

const usePersistentModalsStore = create(
  persist<PersistentModalsStore>(
    () => ({
      dismissedModals: [],
      modalLastSeenDates: MODAL_LAST_SEEN_DATES_DEFAULT_VALUES,
    }),
    {
      name: EnumLocalStorageKeys.PERSISTENT_MODALS,
    }
  )
)

export const useHasDismissedPersistentModal = (
  modalKey: EnumPersistentModalKey
) =>
  usePersistentModalsStore((state) => state.dismissedModals.includes(modalKey))

export const usePersistantModalLastSeenDate = (
  modalKey: EnumPersistentModalKey
) => usePersistentModalsStore((state) => state.modalLastSeenDates[modalKey])

export const usePersistentModalsActions = (): PersistentModalsActions => {
  const { setState } = usePersistentModalsStore

  const setModalLastSeenDate = (modalKey: EnumPersistentModalKey) => {
    setState(({ modalLastSeenDates }) => {
      return {
        modalLastSeenDates: {
          ...modalLastSeenDates,
          [modalKey]: new Date(),
        },
      }
    })
  }

  return {
    addToDismissedModals: (modalKey) => {
      setState(({ dismissedModals }) => {
        if (dismissedModals.includes(modalKey)) {
          return { dismissedModals }
        }
        return {
          dismissedModals: [...dismissedModals, modalKey],
        }
      })
    },
    clearDismissedModals: () => {
      setState({ dismissedModals: [] })
    },
    setModalLastSeenDate,
  }
}
