import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { EnumLocalStorageKeys } from '_constants'
import localStorage from '../lib/storage/localStorage'
import { EnumBannerKey } from '../types/banner'

interface BannerStore {
  bannersToHide: EnumBannerKey[]
}
interface BannerActions {
  addBannerToHide: (bannerKey: EnumBannerKey) => void
}

const useBannerStore = create(
  persist<BannerStore>(
    () => ({
      bannersToHide:
        // Set old BANNERS_TO_HIDE_DEPRECATED key as default value while BANNERS_TO_HIDE key is empty
        localStorage.retrieve<EnumBannerKey[]>(
          EnumLocalStorageKeys.BANNERS_TO_HIDE_DEPRECATED
        ) || [],
    }),
    {
      name: EnumLocalStorageKeys.BANNERS_TO_HIDE,
    }
  )
)

export const useBannerToHideKeys = () =>
  useBannerStore((state) => state.bannersToHide)

export const useHasBannerBeenClosed = (bannerKey: EnumBannerKey) =>
  useBannerStore((state) => state.bannersToHide.includes(bannerKey))

export const useBannerActions = (): BannerActions => {
  const { setState } = useBannerStore

  return {
    addBannerToHide: (bannerKey) => {
      setState(({ bannersToHide }) => {
        if (bannersToHide.includes(bannerKey)) {
          return { bannersToHide }
        }
        return { bannersToHide: [...bannersToHide, bannerKey] }
      })
    },
  }
}
