import dayjs from 'dayjs'
import { useEffect } from 'react'
import {
  useHasDismissedPersistentModal,
  usePersistantModalLastSeenDate,
  usePersistentModalsActions,
} from '../store/usePersistentModalsStore'
import { Modal } from '../types/modal'
import { EnumPersistentModalKey } from '../types/persistentModal'

interface Props {
  modalKey: EnumPersistentModalKey
  displayCondition: boolean
  modal: Modal
  minDaysBeforeNewModalDisplay?: number
}
export const useDisplayPersistentModalOnMount = ({
  displayCondition,
  modal,
  modalKey,
  minDaysBeforeNewModalDisplay = 0,
}: Props) => {
  const hasDismissedModal = useHasDismissedPersistentModal(modalKey)
  const modalLastSeenDate = usePersistantModalLastSeenDate(modalKey)
  const hasModalNeverBeenSeen = !modalLastSeenDate

  const { setModalLastSeenDate } = usePersistentModalsActions()

  const today = dayjs().startOf('day')
  const modalLastSeenDay = dayjs(modalLastSeenDate).startOf('day')
  const daysSinceLastDisplay = today.diff(modalLastSeenDay, 'day')

  const isModalToBeDisplayed =
    displayCondition &&
    !hasDismissedModal &&
    (hasModalNeverBeenSeen ||
      daysSinceLastDisplay >= minDaysBeforeNewModalDisplay)

  useEffect(() => {
    if (isModalToBeDisplayed) {
      modal.showModal()
      setModalLastSeenDate(modalKey)
    }
  }, [isModalToBeDisplayed, modal, modalKey, setModalLastSeenDate])
}
