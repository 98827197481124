import dayjs from 'dayjs'

interface Props {
  givenDate: string
  numberOfDays: number
}
export function isDateWithinNextNDays({ givenDate, numberOfDays }: Props) {
  const parsedDate = dayjs(givenDate)
  const now = dayjs()
  const nDaysLater = now.add(numberOfDays, 'day')

  // The fourth parameter is about inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion.
  // https://day.js.org/docs/en/query/is-between
  return parsedDate.isBetween(now, nDaysLater, 'day', '[]')
}
