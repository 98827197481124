import { CorporateBillingRecordApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

export interface CorporateBillingRecordsQueryParams {
  fromDate?: string
  toDate?: string
  isUnpaid?: boolean
}

interface Props {
  corporateId: string
  queryParams: CorporateBillingRecordsQueryParams | undefined
  accessToken: string
}

export const fetchCorporateBillingRecords = async ({
  corporateId,
  queryParams,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/billing/records/${corporateId}`

  const params: Record<string, any> = {
    ...queryParams,
  }

  if (queryParams?.isUnpaid !== undefined) {
    params.isUnpaid = queryParams.isUnpaid ? 1 : 0
  }

  const { data } = await apiClient.get<CorporateBillingRecordApi[]>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
    params,
  })

  return data || []
}
