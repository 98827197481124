import {
  IconChevronRight,
  IconSupport,
} from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { ListItem } from 'modules/common/components/_ui/ListItem/ListItem'
import { ListItemsContainer } from 'modules/common/components/_ui/ListItemsContainer/ListItemsContainer'
import { Message } from 'modules/common/components/_ui/Message/Message'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  onSupportButtonClick: () => void
}
export const CardNotReceivedModalContent = ({
  onSupportButtonClick,
}: Props) => {
  const { t } = useTranslation(['bank-components', 'common'])

  const messages = t('bank-components:cardNotReceivedModal.messages', {
    returnObjects: true,
  })

  return (
    <div className="flex flex-col gap-6 text-left">
      <div className="flex flex-col items-start gap-3">
        <p className="label-base text-info-400">
          {t('cardNotReceivedModal.messagesHeader')}
        </p>
        {messages.map((label) => (
          <Message key={label} type="important" content={label} withIcon />
        ))}
      </div>
      <div className="flex flex-col items-start gap-3">
        <p className="label-base text-info-400">
          {t('cardNotReceivedModal.supportHeader')}
        </p>

        <ListItemsContainer className="w-full">
          <Button
            variant="none"
            type="button"
            onClick={() => {
              onSupportButtonClick()
            }}
          >
            <ListItem
              title={t('cardNotReceivedModal.supportButton')}
              icon={<IconSupport />}
              rightElement={<IconChevronRight />}
            />
          </Button>
        </ListItemsContainer>
      </div>
    </div>
  )
}
