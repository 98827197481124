import { useTranslation } from 'modules/common/hooks/useTranslation'
import { ContentModalProps } from 'modules/common/modals/useContentModal/useContentModal'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import useSupportModal from 'modules/common/modals/useSupportModal/useSupportModal'
import { CardNotReceivedModalContent } from './CardNotReceivedModalContent'

type Props = Pick<ContentModalProps, 'onBack'>

export const useCardNotReceivedModal = ({ onBack }: Props = {}) => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])

  const { showModal: showSupportModal } = useSupportModal({
    overlayTransitionType: 'transition-none',
  })

  const { hideModal, showModal } = useCtaModal({
    width: 'large',
    content: (
      <CardNotReceivedModalContent
        onSupportButtonClick={() => {
          hideModal()
          showSupportModal()
        }}
      />
    ),
    headerTitle: t('cardNotReceivedModal.title'),
    overlayTransitionType: 'transition-none',
    primaryButtonText: t('common:understood'),
    onBack,
    hideOnBack: true,
    i18n,
  })

  return { hideModal, showModal }
}
