import { CorporateBillingRecordApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import {
  CorporateBillingRecordsQueryParams,
  fetchCorporateBillingRecords,
} from '../api/fetchCorporateBillingRecords'
import { EnumBillingQueryKeys } from './keys'

export const useCorporateBillingRecordsQuery = (
  corporateId: string | undefined,
  queryParams?: CorporateBillingRecordsQueryParams,
  queryOptions?: QueryOptions<CorporateBillingRecordApi[], Error>
) => {
  return useQueryWithAuth<CorporateBillingRecordApi[], Error>({
    queryKey: [
      EnumBillingQueryKeys.CORPORATE_BILLING_RECORDS,
      // The key must only be `[EnumBillingQueryKeys.CORPORATE_BILLING_RECORDS]` when there is no query params
      ...(queryParams ? [queryParams] : []),
    ],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateBillingRecordsQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateBillingRecords({
        corporateId,
        queryParams,
        accessToken,
      })
    },
    enabled: Boolean(corporateId),
    ...queryOptions,
  })
}
