import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import { UnpaidRecordsModalContent } from './UnpaidRecordsModalContent'

interface Props {
  balanceOwed: number
}

export const useUnpaidRecordsModal = ({ balanceOwed }: Props) => {
  const { t, i18n } = useTranslation(['billing-components', 'common'])

  return useCtaModal({
    width: 'base',
    i18n,
    content: <UnpaidRecordsModalContent balanceOwed={balanceOwed} />,
    primaryButtonText: t('common:noted'),
    headerTitle: t('unpaidRecordsBannerModal.headerTitle'),
  })
}
