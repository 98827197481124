import { useUnpaidRecordsModal } from 'modules/billing/modals/UnpaidRecordsModal/useUnpaidRecordsModal'
import { IconDanger } from 'modules/common/components/_icons/icons'
import { BannerMessage } from 'modules/common/components/_ui/BannerMessage/BannerMessage'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'

interface Props {
  balanceOwed: number
}
export const UnpaidRecordsBannerMessage = ({ balanceOwed }: Props) => {
  const { t } = useTranslation('billing-components')
  const { showModal: showUnpaidRecordsModal } = useUnpaidRecordsModal({
    balanceOwed,
  })

  return (
    <BannerMessage icon={<IconDanger />} type="danger">
      <Trans
        components={{
          linkComponent: (
            <Button
              variant="none"
              color="danger"
              onClick={showUnpaidRecordsModal}
            />
          ),
        }}
        parent="p"
      >
        {t('unpaidRecordsBannerMessage')}
      </Trans>
    </BannerMessage>
  )
}
