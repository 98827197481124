import { Illustration } from 'modules/common/components/Illustrations/Illustration'
import { Amount } from 'modules/common/components/_ui/Amount/Amount'
import { Message } from 'modules/common/components/_ui/Message/Message'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  balanceOwed: number
}
export const UnpaidRecordsModalContent = ({ balanceOwed }: Props) => {
  const { t } = useTranslation('billing-components')

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Illustration name="expense-error" size="l" />
      </div>

      <h3 className="font-semibold">{t('unpaidRecordsBannerModal.title')}</h3>

      <div className="flex flex-col items-center">
        <p>{t('unpaidRecordsBannerModal.balanceOwed')}</p>
        <Amount amount={balanceOwed} size="md" />
      </div>

      <p>{t('unpaidRecordsBannerModal.text')}</p>

      <Message
        className="w-full"
        type="important"
        withIcon
        content={t('unpaidRecordsBannerModal.message')}
      />
    </div>
  )
}
