import { EnumMigrationStatus } from '@blank/api'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useDisplayPersistentModalOnMount } from 'modules/common/hooks/useDisplayPersistentModalOnMount'
import { isDateWithinNextNDays } from 'modules/common/lib/helpers/date/isDateWithinNextNDays'
import { EnumPersistentModalKey } from 'modules/common/types/persistentModal'
import { useActivateTokamakCardModal } from './ActivateTokamakCardModal/useActivateTokamakCardModal'

const MODAL_KEY = EnumPersistentModalKey.ACTIVATE_TOKAMAK_CARD

export const useDisplayActivateTokamakCardModalOnMount = () => {
  const { data: internalAccount } = useInternalAccount()
  const limitActivationDate = internalAccount?.limitActivationDate || ''
  const displayCondition =
    internalAccount?.migrationStatus === EnumMigrationStatus.CARD_ORDERED &&
    isDateWithinNextNDays({ givenDate: limitActivationDate, numberOfDays: 10 })

  const modal = useActivateTokamakCardModal()

  useDisplayPersistentModalOnMount({
    displayCondition,
    modalKey: MODAL_KEY,
    modal,
    minDaysBeforeNewModalDisplay: 1,
  })
}
