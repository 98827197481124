import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import { useCardNotReceivedModal } from '../CardNotReceivedModal/useCardNotReceivedModal'
import { ActivateTokamakCardModalContent } from './ActivateTokamakCardModalContent'

export const useActivateTokamakCardModal = () => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])
  const { showModal: showCardNotReceivedModal } = useCardNotReceivedModal({
    onBack: () => showActivateTokamakCardModal(),
  })

  const { showModal: showActivateTokamakCardModal, hideModal } = useCtaModal({
    width: 'large',
    content: <ActivateTokamakCardModalContent />,
    overlayTransitionType: 'transition-none',
    primaryButtonText: t('common:understood'),
    secondaryButtonText: t('activateTokamakCardModal.cardNotReceivedButton'),
    onSecondaryClick: showCardNotReceivedModal,
    i18n,
  })

  return { showModal: showActivateTokamakCardModal, hideModal }
}
