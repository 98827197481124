import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useCorporateBillingRecordsQuery } from 'modules/billing/queries/useCorporateBillingRecordsQuery'
import { useBanner } from 'modules/common/hooks/useBanner'
import { EnumBannerKey } from 'modules/common/types/banner'

export const useUnpaidRecordsBanner = () => {
  const { data: authenticatedUser } = useAuthenticatedUserQuery()
  const { data: unpaidRecords = [], isSuccess } =
    useCorporateBillingRecordsQuery(authenticatedUser?.corporateId, {
      isUnpaid: true,
    })

  const displayCondition = isSuccess && unpaidRecords.length > 0

  const balanceOwed =
    unpaidRecords.reduce((acc, record) => acc + record.billedAmount, 0) / 100

  const banner = useBanner({
    bannerKey: EnumBannerKey.UNPAID_RECORDS,
    displayCondition,
  })

  return { ...banner, balanceOwed }
}
